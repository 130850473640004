import { render, staticRenderFns } from "./cropperImage.vue?vue&type=template&id=3821cbed&scoped=true&"
import script from "./cropperImage.vue?vue&type=script&lang=js&"
export * from "./cropperImage.vue?vue&type=script&lang=js&"
import style0 from "./cropperImage.vue?vue&type=style&index=0&id=3821cbed&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3821cbed",
  null
  
)

export default component.exports