<template>
  <div class="cropper-content">
    <div class="cropper-box">
      <!--底部操作工具按钮-->
      <div class="footer-btn">
        <div class="scope-btn">
          <label class="btn" for="uploads">{{$t('lang.NText163')}}</label>
          <input
            type="file"
            id="uploads"
            style="position: absolute; clip: rect(0 0 0 0)"
            accept="image/png, image/jpeg, image/gif, image/jpg"
            @change="selectImg($event)"
          />
          <div class="JPG">JPG or PNG file & size < 5MB<br/> H: >200px & W: >200px </div>
          
        </div>
        <div class="change">
          <div class="change-botton" @click="changeScale(1)">
            <svg-icon icon-class="zoom_in"></svg-icon>
          </div>
          <div
            class="change-botton"
            style="margin-right: 15px"
            @click="changeScale(-1)"
          >
            <svg-icon icon-class="zoom_out"></svg-icon>
          </div>
          <div class="change-botton" @click="rotateLeft">
            <svg-icon icon-class="left1"></svg-icon>
          </div>
          <div class="change-botton" @click="rotateRight">
            <svg-icon icon-class="right1"></svg-icon>
          </div>
        </div>
      </div>
      <div class="cropper">
        <vue-cropper
          ref="cropper"
          :img="option.img"
          :outputSize="option.outputSize"
          :outputType="option.outputType"
          :info="option.info"
          :canScale="option.canScale"
          :autoCrop="option.autoCrop"
          :autoCropWidth="option.autoCropWidth"
          :autoCropHeight="option.autoCropHeight"
          :fixed="option.fixed"
          :fixedNumber="option.fixedNumber"
          :full="option.full"
          :fixedBox="option.fixedBox"
          :canMove="option.canMove"
          :canMoveBox="option.canMoveBox"
          :original="option.original"
          :centerBox="option.centerBox"
          :height="option.height"
          :infoTrue="option.infoTrue"
          :maxImgSize="option.maxImgSize"
          :enlarge="option.enlarge"
          :mode="option.mode"
          @realTime="realTime"
          @imgLoad="imgLoad"
        >
        </vue-cropper>
      </div>
    </div>
    <!--预览效果图-->
    <div class="show-preview">
      <div class="show-preview-title">{{$t('lang.NText164')}}</div>
      <div :style="previews.div" class="preview">
        <img
          class="image"
          :src="previews.url"
          :style="previews.img"
          style="width: 200px; height: 200px"
        />
      </div>
      <el-button class="primarySmall upload-btn" @click="uploadImg('blob')"
        >{{$t('lang.NText165')}}
      </el-button>
    </div>
  </div>
</template>

<script>
import { VueCropper } from "vue-cropper";
export default {
  name: "CropperImage",
  components: {
    VueCropper,
  },
  props: ["Name"],
  data() {
    return {
      name: this.Name,
      previews: {},
      option: {
        img: "", //裁剪图片的地址
        outputSize: 1, //裁剪生成图片的质量(可选0.1 - 1)
        outputType: "jpeg", //裁剪生成图片的格式（jpeg || png || webp）
        info: true, //图片大小信息
        canScale: true, //图片是否允许滚轮缩放
        autoCrop: true, //是否默认生成截图框
        autoCropWidth: 200, //默认生成截图框宽度
        autoCropHeight: 200, //默认生成截图框高度
        fixed: true, //是否开启截图框宽高固定比例
        fixedNumber: [1, 1], //截图框的宽高比例
        full: false, //false按原比例裁切图片，不失真
        fixedBox: true, //固定截图框大小，不允许改变
        canMove: false, //上传图片是否可以移动
        canMoveBox: true, //截图框能否拖动
        original: true, //上传图片按照原始比例渲染
        centerBox: true, //截图框是否被限制在图片里面
        high: false, //是否按照设备的dpr 输出等比例图片
        infoTrue: true, //true为展示真实输出图片宽高，false展示看到的截图框宽高
        maxImgSize: 3000, //限制图片最大宽度和高度
        enlarge: 1, //图片根据截图框输出比例倍数
        mode: "200px 200px", //图片默认渲染方式
      },
    };
  },
  methods: {
    //初始化函数
    imgLoad(msg) {
      console.log("工具初始化函数=====" + msg);
    },
    //图片缩放
    changeScale(num) {
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },
    //向左旋转
    rotateLeft() {
      this.$refs.cropper.rotateLeft();
    },
    //向右旋转
    rotateRight() {
      this.$refs.cropper.rotateRight();
    },
    //实时预览函数
    realTime(data) {
      this.previews = data;
    },
    //选择图片
    selectImg(e) {
      let file = e.target.files[0];
      if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(e.target.value)) {
        this.$message({
          message: this.$t('lang.NText166'),
          type: "error",
        });
        return false;
      }

      if (file.size > 5242880) {
        this.$message({
          message: this.$t('lang.NText167'),
          type: "error",
        });
        return false;
      }
      //转化为blob
      let reader = new FileReader();
      reader.onload = (e) => {
        const img = e.target.result;
        const image = new Image();
        image.src = img;
        image.onload = (i) => {
          if (image.width < 200 || image.height < 200) {
            this.$message({
              message: this.$t('lang.NText168'),
              type: "error",
            });
            return false;
          } else {
            let data;
            if (typeof e.target.result === "object") {
              data = window.URL.createObjectURL(new Blob([e.target.result]));
            } else {
              data = e.target.result;
            }
            this.option.img = data;
          }
        };
      };
      //转化为base64
      reader.readAsDataURL(file);
    },
    //上传图片
    uploadImg(type) {
      let _this = this;
      if (type === "blob") {
        //获取截图的blob数据
        this.$refs.cropper.getCropBlob(async (data) => {
          let filename = "";
          if (data.type == "image/jpeg" || data.type == "image/pjpeg") {
            filename =
              new Date().getTime() + parseInt(Math.random() * 10) + ".jpeg";
          }
          if (data.type == "image/jpg") {
            filename =
              new Date().getTime() + parseInt(Math.random() * 10) + ".jpg";
          }
          if (data.type == "image/png" || data.type == "image/x-png") {
            filename =
              new Date().getTime() + parseInt(Math.random() * 10) + ".png";
          }
          let imgFile = new window.File([data], filename, {
            type: data.type,
          });
          _this.$emit("uploadImg", imgFile);
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.cropper-content {
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;
  .cropper-box {
    width: 640px;
    .cropper {
      height: 360px;
      width: 640px;
      .cropper-drag-box {
        height: 360px;
        width: 640px;
      }
    }
  }

  .show-preview {
    width: 200px;
    margin-left: 20px;
    position: relative;
    .show-preview-title {
      text-align: left;
      color: #132569;
      line-height: 50px;
    }
    .preview {
      overflow: hidden;
      border: 1px solid #67c23a;
      background: #cccccc;
      .image {
        width: 200px;
        height: 200px;
      }
    }
    .upload-btn {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
    }
  }
}
.footer-btn {
  margin-bottom: 12px;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  .JPG {
    color: #dcdcdc;
    line-height: 16px;
    font-size: 10px;
    text-align: left;
    margin-top: 4px;
  }
  .scope-btn {
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    padding-right: 10px;
    line-height: 40px;
    height: 40px;
  }
  .change {
    display: flex;
    padding-top: 5px;
    .change-botton {
      height: 30px;
      width: 30px;
      margin-left: 8px;
      border: 1px solid #cccccc;
      line-height: 20px;
      .svg-icon {
        height: 16px;
        display: flex;
        width: 16px;
        margin: auto;
        margin-top: 7px;
        color: #299be4;
      }
    }
  }

  .btn {
    outline: none;
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    -webkit-appearance: none;
    text-align: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: 0;
    -webkit-transition: 0.1s;
    transition: 0.1s;
    font-weight: 500;
    padding: 12px 15px;
    font-size: 12px;
    color: #fff;
    background-color: #132569;
    border-color: #132569;
    margin-right: 10px;
  }
}
</style>

